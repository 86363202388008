import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/* etapas del cambio de clave */
import etapa1 from './components/etapa1';
import etapa2 from './components/etapa2';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={etapa1} />
        <Route exact path="/change" component={etapa2} />
      </Switch>
    </Router>
  );
}

