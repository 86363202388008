import React, { Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text_legend: {
    marginBottom: '20px',
    fontSize: '12px',
    color: '#3f51b5'
  },
}));

export default function IngresaClave(props) {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.text_legend}>
        <Typography variant="body1" gutterBottom>
         Para continuar el proceso, debes ingresar tu nueva clave
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="password"
            label="Nueva clave"
            InputLabelProps={{
              shrink: true,
            }}
            helperText="La nueva clave debe tener un largo entre 8 y 10 caracteres, debe contener letras con al menos una mayúscula y números como también incluir los siguientes caracteres $_#*,%&()?¿,[{^`~¨|><"
            type="password"
            fullWidth
            onChange={props.leerClave}
            variant="outlined"
            inputProps={{
              style: { fontSize: 20 }
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="password_rep"
            label="Repite la clave"
            InputLabelProps={{
              shrink: true,
            }}
            type="password"
            fullWidth
            onChange={props.leerClave_rep}
            variant="outlined"
            inputProps={{
              style: { fontSize: 20 }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}