import React, { Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function BuscaForm(props) {
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="rut"
            label="Ingresa tu rut"
            helperText="Sin punto ni guíon y con digito verificador. Ejemplo: 123456789"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={props.leerRut}
            variant="outlined"
            inputProps={{
              style: { fontSize: 20 }
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}