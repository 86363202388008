import React, { useRef }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";
import AppConfig from "../App.config";
import clienteAxios from '../Config/axios';
import CorreosForm from './etapa1/CorreosForm';
import BuscaForm from './etapa1/BuscaForm';
import EnviaCorreo from './etapa1/EnviaCorreo';
import Copyright from './copyright';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fondo: {
    backgroundColor: '#000',
    height: '100vh',
    display: 'block',
    backgroundImage: "url('../images/background.jpg')"
  },
  footer:{
    color:'#fff'
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const steps = ['Ingresar RUT', 'Seleccionar correo', 'Revisar correo '];
export default function Etapa1() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [rut, setRut] =  React.useState('');
  const [viewAlert, setAlert] = React.useState({show:false, message:''});
  const [token, setToken] =  React.useState('');
  const [correos, setCorreos] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [tkCaptcha, setTkCaptcha] = React.useState('');
  const [correoDest, setCorreoDest] = React.useState('');

  const reCaptcha = useRef();
  const handleCorreo = (event) => {
    setAlert({show:false, message:''})
    setCorreoDest(event.target.value);
  };

  const handleNext = () => {
    if(activeStep === 0){
      if(rut && tkCaptcha && rut !== '' ){
        setTkCaptcha("")
        buscarCorreos()
      }else{
        if(!tkCaptcha){
          setAlert({show:true, message:'Debes marcar captcha'})
        }else{
          setAlert({show:true, message:'Debes ingresar un RUT'})
        }
      }
    } 
    if(activeStep === 1){
      if(correoDest  && correoDest !== ''){
       if(correoDest  && correoDest !== '' ){
        enviaCorreo()
       }else{
        setAlert({show:true, message:'No estas autorizado para seguir con el proceso'})
       }
      }else{
        setAlert({show:true, message:'Debes seleccionar un correo'})
      }
    }
  };

  const handleBack = () => {
    setRut('')
    setToken('')
    setCorreoDest('')

    if(activeStep === 3){
      setActiveStep(activeStep - 2);
    }else{
      setActiveStep(activeStep - 1);
    }
    
  };

  const leerRut = e =>{
    if(rut !== ''){
      setAlert({show:false, message:''})
    }
		setRut(e.target.value)
  }
  
  const buscarCorreos = async () =>{
		try {
      setLoading(true)
      const config = {
          headers: { Authorization: `Bearer ${tkCaptcha}` }
      };
			const resultadoBusqueda = await clienteAxios.get(`/api/usuarios/${rut}`,config)
			if(resultadoBusqueda.data){
        setToken(resultadoBusqueda.data.token)
        const correosUSS = resultadoBusqueda.data.emailuss
        const correosPART = resultadoBusqueda.data.emailpart
        setCorreos({correosUSS, correosPART})
        setActiveStep(activeStep + 1);
        
			}else{
        setAlert({show:true, message:'Ha ocurrido un error, pronto lo solucionaremos'})
			}
		} catch (error) {
      if(error.response){
        setAlert({show:true, message:error.response.data.message})
      }else{
        setAlert({show:true, message:'Ha ocurrido un error, pronto lo solucionaremos'})
      }
      
		} finally {
      setLoading(false)
    }
  }
  
  const enviaCorreo = async () =>{
		try {
      setLoading(true)
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
			await clienteAxios.post(`/api/usuarios/token`, { email: correoDest}, config)
      setActiveStep(3);
		} catch (error) {
      if(error.response){
        setAlert({show:true, message:error.response.data.message})
      }else{
        setAlert({show:true, message:'Ha ocurrido un error, pronto lo solucionaremos. Intenta ingresando más tarde'})
      }
		} finally{
      setLoading(false)
    }
	}

  return (
    <div className={classes.fondo}>
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Box mx="auto" p={1} align="center">
            <img src={"images/logo_blanco.png"} alt="logo USS" />
          </Box>
          <Paper className={classes.paper}>
            <div>
              <Box mx="auto" p={3} align="center">
                <img src={"images/logo_claveunica.svg"} alt="logo USS" width="250px" />
              </Box>
            </div>
            <Typography variant="body2" m={2} gutterBottom>
              Es un servicio que permite a todos los estudiantes, colaboradores y académicos cambiar la clave a todos los servicios USS, como MiPortal, Office 365, Classroom, correo y Autoservicios.
            </Typography>

            <Typography variant="body2" m={2} gutterBottom>
            Para cambiar tu clave única debes ingresar tu RUT y seleccionar el correo que deseas usar para el cambio de clave.
            </Typography>

            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {loading === true && (
              <div className={classes.loading}>
                <Box mx="auto" align="center">
                  <CircularProgress />
                </Box> 
              </div>
            )}

            <div className={classes.root}>
              <Collapse in={viewAlert.show}>
                <Alert severity="error">{viewAlert.message}</Alert>
              </Collapse>
            </div>
            
            {loading === false && (
            <React.Fragment>
              <React.Fragment>
                {
                  (() => {
                    switch (activeStep) {
                      case 0:
                        return <BuscaForm
                          leerRut={leerRut}
                        />
                      case 1:
                        return <CorreosForm 
                                  correos={correos}
                                  correoDest={correoDest}
                                  handleCorreo={handleCorreo}
                                />;
                      case 3:
                        return <EnviaCorreo />;
                      default:
                        throw new Error('Unknown step');
                    }
                  }).call(this)
                }
                <div className={classes.buttons}>

                  {activeStep === 0 && (

                    <ReCAPTCHA
                        style={{ flex: 1 }}
                        ref={reCaptcha}
                        sitekey= {AppConfig.GOOGLE.reCaptcha}
                        onChange={tkCaptcha => setTkCaptcha(tkCaptcha)}
                    />

                  )}

                  {activeStep !== 0 && activeStep !== 3 && (
                    <Button 
                      onClick={handleBack} 
                      className={classes.button}>
                      Volver
                    </Button>
                  )}

                  {activeStep < 2 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    > 
                      {activeStep === 1 ? 'Enviar correo' : 'Siguiente'}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    </div>
  );
}