import React  from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    footer:{
      color:'#fff'
    }
  }));

export default function Copyright() {
    const classes = useStyles();
    return (
      <Typography variant="body2" color="textSecondary" align="center" className={classes.footer}>
        {'Copyright © '}
        <Link color="inherit" href="https://claveunica.uss.cl/" className={classes.footer}>
          Clave Unica
        </Link>{' '}
        -
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }