import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DraftsIcon from '@material-ui/icons/Drafts';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  blue: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));

export default function EnviaCorreo() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
              <Avatar className={classes.blue}>
                <DraftsIcon />
              </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Correo enviado correctamente"
            secondary="Para continuar el proceso debes seguir las instrucciones que hemos enviado a tu correo electrónico seleccionado anteriormente"
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
}