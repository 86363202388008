import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import Copyright from './copyright';
import CircularProgress from '@material-ui/core/CircularProgress';

import clienteAxios from '../Config/axios';
import IngresaClave from './etapa2/IngresaClave';
import Resultado from './etapa2/Resultado';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fondo: {
    backgroundColor: '#000',
    height: '100vh',
    display: 'block',
    backgroundImage: "url('../images/background.jpg')"
  },
  footer: {
    color: '#fff'
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

const steps = ['Verificación de link', 'Cambiar clave', 'Resultado'];
export default function Etapa2() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [token, setToken] = React.useState('');
  const [viewAlert, setAlert] = React.useState({ show: false, message: '' });
  const [clave, setClave] = React.useState('');
  const [clave_rep, setClave_rep] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleNext = () => {
    if (activeStep === 1) {
      if (validaClave()) {
        enviaClave()
      }
    }
  }

  const leerClave = e => {
    setClave(e.target.value)
  }

  const leerClave_rep = e => {
    setClave_rep(e.target.value)
  }

  const validaToken = async () => {
    setLoading(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tk = params.get('tk');
    if (tk) {
      try {
        let response = await clienteAxios.get(`/api/usuarios/token/${tk}`)
        setToken(response.data.token)
        setActiveStep(1)
      } catch (error) {
        setActiveStep(-1)
        if (error.response) {
          setAlert({ show: true, message: 'Acceso no autorizado, el link se encuentra expirado. Por favor realizar nuevamente el proceso' })
        } else {
          setAlert({ show: true, message: 'Ha ocurrido un error, pronto lo solucionaremos' })
        }
      }
    } else {
      setActiveStep(-1)
      setAlert({ show: true, message: 'Acceso no autorizado, sin verificación. Revisa tu correo e ingresa al link nuevamente o vuelve a realizar el proceso' })
    }
    setLoading(false)
  };
 
  const validaClave = () => {
    if (!clave) {
      setAlert({ show: true, message: 'Debes ingresar una clave' })
    } else if (clave.length < 8 || clave.length > 10) {
      setAlert({ show: true, message: 'La nueva clave debe tener un largo entre 8 y 10 caracteres' })
    } else if (!(new RegExp(/[0-9]/).test(clave))) {
      setAlert({ show: true, message: 'La nueva clave debe tener al menos un número' })
    } else if (!(new RegExp(/[a-z]/).test(clave)) || !(new RegExp(/[A-Z]/).test(clave))) {
      setAlert({ show: true, message: 'La nueva clave debe tener al menos una letra mayúscula y una minúscula' })
    } else if (!(new RegExp(/[$_#*,%&()?¿,[{^`~¨|><]/).test(clave))) {
      setAlert({ show: true, message: 'La nueva clave debe tener al menos un caracter especial' })
	} else if (clave !== clave_rep) {
      setAlert({ show: true, message: 'Las claves no coinciden' })
    } else {
      setAlert({ show: false, message: '' })
      return true
    }
  };

  const enviaClave = async () => {
    try {
      setLoading(true)
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await clienteAxios.post(`/api/usuarios`, { password: clave, password_r: clave_rep }, config)
      setActiveStep(2)
    } catch (error) {
      setActiveStep(-1)
      if (error.response) {
        setAlert({ show: true, message: error.response.data.message })
      } else {
        setAlert({ show: true, message: 'Ha ocurrido un error, pronto lo solucionaremos. Intenta ingresando más tarde' })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={classes.fondo}>
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Box mx="auto" p={1} align="center">
            <img src={"images/logo_blanco.png"} alt="logo USS" />
          </Box>
          <Paper className={classes.paper}>
            <div>
              <Box mx="auto" p={3} align="center">
                <img src={"images/logo_claveunica.svg"} alt="logo USS" width="250px" />
              </Box>
            </div>
            <Typography variant="body2" m={2} gutterBottom>
              Recuerda que esta clave te servirá para ingresar a todos los servicios de la Universidad San Sebastián de manera fácil y segura.
            </Typography>
            {activeStep !== -1 && (
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel icon={index + 4}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}

            {loading === true && (
              <div className={classes.loading}>
                <Box mx="auto" align="center">
                  <CircularProgress />
                </Box>
              </div>
            )}

            <div className={classes.root}>
              <Collapse in={viewAlert.show}>
                <Alert severity="error">{viewAlert.message}</Alert>
              </Collapse>
            </div>
          
            {loading === false && (
              <React.Fragment>
                <React.Fragment>
                  {
                    (() => {
                      switch (activeStep) {
                        case -1:
                          return ''
                        case 0:
                          if (!token) {
                            validaToken();
                          }
                          break
                        case 1:
                          return <IngresaClave
                            leerClave={leerClave}
                            leerClave_rep={leerClave_rep}
                          />;
                        case 2:
                          return <Resultado />
                        default:
                          throw new Error('Unknown step');
                      }
                    }).call(this)
                  }
                  <div className={classes.buttons}>
                    {activeStep === 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        Siguiente
                      </Button>
                    )}

                    {activeStep === -1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        href="/"
                        className={classes.button}
                      >
                        Volver a realizar el proceso
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </main>
      </React.Fragment>
    </div>
  );
}