import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
  demo: {
    border: '#ccc solid 1px',
    marginBottom: '20px',
    borderRadius: 5
  }
}));


export default function CorreosForm(props) {
  const classes = useStyles();

  const listPart = (items) => (
    <RadioGroup aria-label="email" name="email" value={props.correoDest} onChange={props.handleCorreo}>
      {items.map((value) => {
        return (
          <FormControlLabel key={value.email} value={value.value} control={<Radio />} label={value.email} />
        );
      })}
    </RadioGroup>
);


  return (
    <React.Fragment>
      <div className={classes.demo} >
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Para continuar con el cambio de clave, selecciona una casilla a la que tengas acceso, si es tu primer ingreso como estudiante nuevo debes seleccionar tu casilla personal.</FormLabel>
            {props.correos.correosPART.length > 0 
              ? listPart(props.correos.correosPART)
              : listPart(props.correos.correosUSS)
            }
          <FormHelperText>Si ninguno de los correos que tenemos registrado corresponde, debes dirigirte a registro académico de tu sede.</FormHelperText>
        </FormControl>
      </div>
    </React.Fragment>
  );
}