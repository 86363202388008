import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  blue: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));

export default function Resultado() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
              <Avatar className={classes.blue}>
                <CheckCircleOutlineIcon />
              </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Se ha cambiado correctamente tu clave"
            secondary="Recuerda que esta clave te servirá para ingresar a todos los servicios de la Universidad San Sebastián de manera fácil y segura."
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
}